window.modalImageFullScreenHandler = function () {
  $(document).ready(() => {
    modalImageFullScreenEvents();
  });

  const modalImageFullScreenEvents = function () {

    // show full screen image in gallery
    $(document).on('click', '.jsGalleryModalFullImage', function () {
      $('.jsModalSlideFullScreenImg').css({'visibility': 'visible', 'opacity': '1'});
      $('.jsModalSlideLargeImg').attr('src', $(this).data('image-url'));
      $('body').css({'overflow': 'hidden'});
    });

    // hide full screen image
    $('.jsModalSlideLargeImg').on('click', function () {
      $(this).parents('.jsModalSlideFullScreenImg').css({'visibility': 'hidden', 'opacity': '0'});
      $(this).attr('src', '');
      $('body').css({'overflow': 'auto'});
    });

    // close full screen image
    $('.jsModalSlideFullScreenImgClose').on('click', function () {
      $(this).parents('.jsModalSlideFullScreenImg').css({'visibility': 'hidden', 'opacity': '0'});
      $(this).siblings('.jsModalSlideLargeImg').attr('src', '');
      $('body').css({'overflow': 'auto'});
    });
  };
};
